import React from 'react'
import { alpha, colors, s } from '../style'
import CloseIcon from '../assets/CloseIcon'

const Popup = ({ open, setOpen, children, center }) => {
  return (
    <>
      <div
        aria-label='open'
        css={[sDimmer, !open && { opacity: 0, pointerEvents: 'none' }]}
        onClick={() => setOpen(false)}
        onKeyPress={() => setOpen(false)}
        tabIndex={0}
        role='button'
      />
      <div
        css={[
          sOuter,
          !open && { opacity: 0, pointerEvents: 'none' },
          center && {
            width: '100vw',
            justifyContent: 'center',
          },
        ]}>
        <div css={{ position: 'relative', top: 0, left: 0 }}>
          <div css={[sInner, center && sInnerCenter]}>{open && children}</div>
          <div
            css={sCloseIcon}
            onClick={() => setOpen(false)}
            onKeyPress={() => setOpen(false)}
            tabIndex={0}
            role='button'>
            <CloseIcon />
          </div>
        </div>
      </div>
    </>
  )
}

const sCloseIcon = {
  position: 'absolute',
  right: '1.5rem',
  top: '1.5rem',
  cursor: 'pointer',
  zIndex: 2000,
  opacity: 0.3,
  svg: {
    width: '1.5rem',
    height: '1.5rem',
  },
  [s.xs]: {
    right: '1rem',
    top: '1rem',
    svg: {
      width: '1rem',
      height: '1rem',
    },
  },
}

const sOuter = {
  position: 'fixed',
  top: 0,
  right: 0,
  height: '100vh',
  maxHeight: '100vh',
  width: 'max-content',
  zIndex: 2000,
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0.5s cubic-bezier(0.25, 1, 0.5, 1)',
  willChange: 'transform',
}

const sInner = {
  position: 'relative',
  boxSizing: 'border-box',
  height: '100vh',
  maxHeight: '100vh',
  backgroundColor: 'white',
  borderRadius: '2rem 0 0 2rem',
  overflowY: 'auto',
  overflowx: 'visible',
  [s.xs]: {
    width: 'calc(100vw - 2rem)',
    padding: '2rem',
  },
  [s.sm]: {
    width: '75vw',
    padding: '2rem',
  },
  [s.md]: {
    padding: '4rem',
  },
  [s.lg]: {
    width: '50vw',
  },

  '::-webkit-scrollbar': {
    width: 0,
  },
}

const sInnerCenter = {
  height: 'max-content',
  maxHeight: 'max-content',
  [s.xs]: {
    minHeight: 'calc(100vw - 2rem)',
    borderRadius: '1.5rem',
  },
  // margin: '0 auto',
  maxWidth: 960,
  borderRadius: '2rem',
}

const sDimmer = {
  transition: 'opacity 0.5s cubic-bezier(0.25, 1, 0.5, 1)',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: colors.greyDarker.concat(alpha[90]),
  zIndex: 1100,
  cursor: 'pointer',
}

export default Popup
